(function ($) {
  Drupal.behaviors.headerOffersBannerFormatterV2 = {
    attached: false,
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;
      var $formatter = $('.js-header-offers-banner-formatter-v2', context);
      $formatter.each(function () {
        var $carousel = $('.js-header-offers-banner-formatter-carousel', $formatter);
        var $slides = $carousel.find('.header-offers-banner-formatter__carousel-item');
        var slideIndex = 1;
        var timer = null;
        var autoplay = $carousel.data('slides-autoplay');
        var speed = parseInt($carousel.data('speed'));
  
        showSlides(slideIndex);
  
        $carousel.removeClass('not-initialized');
  
        function showSlides(n) {
          if (n === undefined) {
            n = ++slideIndex;
          }
          if (n > $slides.length) {
            slideIndex = 1;
          }
          if (n < 1) {
            slideIndex = $slides.length;
          }
          $slides.removeClass('item-active');
          $slides.eq(slideIndex - 1).addClass('item-active');
          if (autoplay) {
            timer = setTimeout(showSlides, speed);
          }
        }
      });
    }
  };
})(jQuery);
